::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #C399FF;
  scrollbar-color: #C399FF;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb:hover {
  background: #C399FF;
}

::-webkit-scrollbar-track {
  background: #CBD5E1;
}

::-webkit-scrollbar-track:hover {
  background: #CBD5E1;
}
